@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css);
@import url(https://cdn.jsdelivr.net/npm/bulma@0.9.4/css/bulma.min.css);
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --cbl-green: 33, 104, 105;
  --cbl-grey: 150, 150, 150;
  --pbl-purple: 42, 40, 106, 1;
  --pbl-gold: 249, 182, 115, 1;
  --pbl-white: 255, 255, 255;
  --pbl-grey: 150, 150, 150;
  --pbl-black: #000000;
}
html,
body {
  height: 100%;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
#root {
  height: 100%;
  padding-bottom: 1px;
}
body {
  font-family: "Rubik", sans-serif;
  background-color: #fff;
  margin: 0px;
  padding: 1px;
  -webkit-font-smoothing: antialiased;
}
a {
  text-decoration: none;
  color: inherit;
}
.center-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.green {
  color: rgba(17, 196, 199, 0.747);
}
